import React from "react"
import { Link } from "gatsby";
import './styles.scss';

const Navbar: React.FC = () => {
    return (
        <nav className="navbar">
            <div className="container w-full px-4 navbar-container mx-auto">
                <Link className="navbar-brand" to="/"><img src="/logo.svg" alt="En nombre de Dios" height="56px" width="43px" /></Link>
            </div>
        </nav>
    )
}
export default Navbar
